'use client';

import { _themed } from '@aw/utils';
import React, { Dispatch, ReactNode, useEffect, useState } from 'react';
import { useAuth } from '@/hooks/auth';
import axios from '@/lib/axios';
import { OrganizationData, organizations } from '@/data/organizations';
import useSWR from 'swr';

interface User {
    id: string;
}

interface ResponseData {
    data: any[];
}

const useOrganizations = () => {
    const { data, mutate } = useSWR('/user/organizations', async () => {
        const res = await axios.get<ResponseData>('/user/organizations');
        return res.data.data;
    });

    return { organizations: data || [], mutate };
};

interface WorkSpaceContextInterface {
    organizations: any[];
    currentOrganization: string | number | null;
    setCurrentOrganization: Dispatch<React.SetStateAction<string | null | number>>;
    userPermissions: string[];
    getOrganizationData: () => OrganizationData | undefined;
}

const WorkSpaceContext = React.createContext<WorkSpaceContextInterface>({
    organizations: [],
    currentOrganization: null,
    setCurrentOrganization: () => null,
    userPermissions: [],
    getOrganizationData: () => undefined,
});

interface WorkSpaceProviderProps {
    children: ReactNode;
}

const WorkSpaceProvider: React.FC<WorkSpaceProviderProps> = ({ children }) => {
    const { user } = useAuth();
    const { organizations, mutate } = useOrganizations();

    const [currentOrganization, setCurrentOrganization] = useState<string | number | null>(null);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    useEffect(() => {
        if (!user || organizations.length === 0) return;

        const storedOrg = localStorage.getItem(`${user.id}organization`);
        if (!storedOrg && organizations.length > 0) {
            const defaultOrg = organizations[0].uid;
            localStorage.setItem(`${user.id}organization`, defaultOrg);
            setCurrentOrganization(defaultOrg);
        } else if (storedOrg && storedOrg !== currentOrganization) {
            setCurrentOrganization(storedOrg);
        }
    }, [organizations, user]);

    useEffect(() => {
        if (!currentOrganization || !user?.id) return;

        const getPermissions = async () => {
            try {
                const res = await axios.get(`/organization/${currentOrganization}/user/${user.id}/permissions`);
                setUserPermissions(res.data.data);
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        getPermissions();
    }, [currentOrganization]);

    const getOrganizationData = (): OrganizationData | undefined => {
        return organizations.find(org => org.uid === currentOrganization);
    };

    return (
        <WorkSpaceContext.Provider
            value={{
                organizations,
                currentOrganization,
                setCurrentOrganization,
                userPermissions,
                getOrganizationData,
            }}
        >
            {children}
        </WorkSpaceContext.Provider>
    );
};

export { WorkSpaceProvider, WorkSpaceContext };
